import { Fragment, useEffect, useState } from 'react';
import DraggableModal from '../../shared/components/DraggableModal';
import { createClass, getClassesByTeacher } from '../../network/Endpoint';

const ClassChangeModal = ({ showClassChangeModal, closeClassChangeModel, imTeacherLoginId, setClazz, fetchStudentsByClass }) => {
  const [formData, setFormData] = useState({
    name: '',
  });
  const [classes, setClasses] = useState([]);

  const fetchClassesByTeacher = async (body) => {
    try {
      const res = await getClassesByTeacher(body);
      const statusCode = res.data.status.code;
      if (statusCode === 'Success') {
        setClasses(res.data.data.classes);
      } else {
        alert(`[${statusCode}] ERROR: 오류가 발생했어요. 아크수학으로 문의해 주세요.`);
      }
    } catch (error) {
      alert('오류가 발생했어요. 아크수학으로 문의해 주세요.');
    }
  };

  const changeClass = (value) => {
    setClazz(value);
    const body = {
      imClassId: value.imClassId,
      imTeacherLoginId: imTeacherLoginId,
    };
    fetchStudentsByClass(body);
    closeClassChangeModel();
  };

  const submitClassCreation = async (e) => {
    e.preventDefault();
    if (formData.name === '') {
      alert('학급 이름을 입력해 주세요.');
      return;
    }
    if (window.confirm('생성하시겠습니까?')) {
      try {
        const body = {
          name: formData.name,
          imTeacherLoginId: imTeacherLoginId,
        };
        const res = await createClass(body);
        const statusCode = res.data.status.code;
        if (statusCode === 'Success') {
          let clazz = res.data.data.clazz;
          setClasses((prevClasses) => [...prevClasses, clazz]);
          setFormData({
            ...formData,
            name: '',
          });
        } else {
          alert(`[${statusCode}] ERROR: 오류가 발생했어요. 아크수학으로 문의해 주세요.`);
        }
      } catch (error) {
        alert('오류가 발생했어요. 아크수학으로 문의해 주세요.');
      }
    }
  };

  const handleFormData = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (showClassChangeModal) {
      const body = {
        imTeacherLoginId: imTeacherLoginId,
      };
      fetchClassesByTeacher(body);
      setFormData({
        ...formData,
        name: '',
      });
    }
  }, [showClassChangeModal]);

  return (
    <Fragment>
      <DraggableModal show={showClassChangeModal} closeModal={closeClassChangeModel} title={'학급 변경'} zIndex={10}>
        <form onSubmit={(e) => submitClassCreation(e)}>
          <input type={'text'} name={'name'} value={formData.name || ''} onChange={handleFormData} className={'border'}/>
          <button type={'submit'} className={'border'}>학급 추가</button>
        </form>
        {classes && classes.map((value, index) => (
          <div key={index} className={'border'} onClick={() => changeClass(value)}>
            <div>{value.name}</div>
          </div>
        ))}
      </DraggableModal>
    </Fragment>
  );
};

export default ClassChangeModal;
