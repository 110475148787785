import { QRCodeCanvas } from 'qrcode.react';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

const QrCodePage = () => {
  const [searchParams] = useSearchParams();

  const stageIdParam = searchParams.get('stageId') || '';
  const stageNameParam = searchParams.get('stageName') || '';
  const classIdParam = searchParams.get('classId') || '';
  const [qrCodeValue, setQrCodeValue] = useState('');

  useEffect(() => {
    // classIdParam이 변경될 때 QR 코드 값을 업데이트
    setQrCodeValue(`https://example.com?stageId=${stageIdParam}&classId=${classIdParam}`);
  }, [classIdParam]);

  return (
    <div>
      <div>
        {stageNameParam}
      </div>
      <div>
        <QRCodeCanvas value={qrCodeValue} size={128} bgColor="#ffffff" fgColor="#000000"/>
      </div>
    </div>
  );
};

export default QrCodePage;
