import { Transition } from 'react-transition-group';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useRef, useState } from 'react';

const modalOverlayStyles = css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(192, 192, 192, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 140ms ease-in-out;
    z-index: auto;
`;

const modalContentStyles = css`
    position: absolute;
    background-color: #ffffff;
    max-width: 90vw;
    max-height: 90vh;
    border-radius: 10px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
    opacity: 0;
    transform: translateY(-60px);
    transition: opacity 140ms ease-in-out, transform 140ms ease-in-out;
    z-index: auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const modalHeaderStyles = css`
    cursor: grab;
    height: 50px;
    border-radius: 10px 10px 0 0;
    border-bottom: 1px solid #e7e9ec;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: auto;
`;

const modalBodyStyles = css`
    overflow: auto;
    padding: 20px;
    max-height: calc(90vh - 50px);
`;

const DraggableModal = ({ show, closeModal, zIndex, title, children, trash, loading }) => {
  const modalRef = useRef(null); // 모달 DOM에 접근하기 위한 ref
  const [isDragging, setIsDragging] = useState(false);
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  const [dragPosition, setDragPosition] = useState({ x: 0, y: 0 });

  const startDrag = (e) => {
    setIsDragging(true);
    setStartPosition({
      x: e.clientX - dragPosition.x,
      y: e.clientY - dragPosition.y,
    });
  };

  const onDrag = (e) => {
    if (!isDragging) return;
    setDragPosition({
      x: e.clientX - startPosition.x,
      y: e.clientY - startPosition.y,
    });
  };

  const stopDrag = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', onDrag);
      document.addEventListener('mouseup', stopDrag);
    } else {
      document.removeEventListener('mousemove', onDrag);
      document.removeEventListener('mouseup', stopDrag);
    }

    return () => {
      document.removeEventListener('mousemove', onDrag);
      document.removeEventListener('mouseup', stopDrag);
    };
  }, [isDragging, onDrag]);

  useEffect(() => {
    if (show) {
      // CSS 트랜지션보다 약간 긴 지연 시간을 설정
      // 이유는 지연 시간을 설정하지 않으면 이 전에 활성화한 모달의 크기를 기준으로 위치를 계산하기 때문에 정중앙으로 초기화되지 않을 수 있다.
      // 모달이 완전히 나타나고 크기가 결정된 이후에 위치를 계산하도록 설정
      const timeoutId = setTimeout(() => {
        if (modalRef.current) {
          const modalWidth = modalRef.current.offsetWidth;
          const modalHeight = modalRef.current.offsetHeight;
          const x = (window.innerWidth - modalWidth) / 2;
          const y = (window.innerHeight - modalHeight) / 2;
          setDragPosition({ x, y });
        }
      }, 150); // CSS 트랜지션보다 약간 긴 지연 시간을 설정
      return () => clearTimeout(timeoutId);
    }
  }, [show]); // 모달이 보여질 때 위치를 재계산

  // 로딩이 끝나면 다시 가운데로 정렬
  useEffect(() => {
    if (!loading) {
      if (modalRef.current) {
        const modalWidth = modalRef.current.offsetWidth;
        const modalHeight = modalRef.current.offsetHeight;
        const x = (window.innerWidth - modalWidth) / 2;
        const y = (window.innerHeight - modalHeight) / 2;
        setDragPosition({ x, y });
      }
    }
  }, [loading]);

  return (
    <Transition in={show} timeout={140} mountOnEnter unmountOnExit>
      {(state) => (
        <div
          css={modalOverlayStyles}
          style={{
            opacity: state === 'entered' ? 1 : 0,
            zIndex: zIndex
          }}
          // onClick={closeModal} // 모달 영역 외 클릭 시 모달 종료
        >
          <div
            ref={modalRef} // ref 연결
            css={modalContentStyles}
            style={{
              opacity: state === 'entered' ? 1 : 0,
              transform: state === 'entered' ? 'translateY(0)' : 'translateY(-60px)',
              zIndex: zIndex,
              left: `${dragPosition.x}px`,
              top: `${dragPosition.y}px`,
            }}
            // onClick={(e) => { // 모달 영역 클릭 시 모달 종료 중지
            //   e.stopPropagation()
            // }}
          >
            {/*<div className="flex justify-end">*/}
            <div css={modalHeaderStyles}
                 style={{
                   cursor: isDragging ? 'grabbing' : 'grab',
                 }}
              // 드래그 시작 이벤트를 제목 바에만 적용
                 onMouseDown={startDrag}>
              <div className={'w-full h-full flex justify-center items-center text-[16px] font-[nanum-square-neo-b]'}>
                {title}
              </div>
              <div onClick={closeModal} className="absolute right-[10px] top-[50%] -translate-y-1/2 cursor-pointer p-[4px] rounded-lg hover:bg-gray-100">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                </svg>
              </div>
              {trash ? (<div onClick={trash} className="absolute left-[10px] top-[50%] -translate-y-1/2 cursor-pointer p-[4px] rounded-lg hover:bg-red-100">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer">
                  <path strokeLinecap="round" strokeLinejoin="round"
                        d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"/>
                </svg>
              </div>) : null}
            </div>
            <div css={modalBodyStyles}>
              {children}
            </div>
          </div>
        </div>
      )}
    </Transition>
  );
};

export default DraggableModal;
