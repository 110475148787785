import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { createStudent, getClassLessonStage, getStudentsByClass, getTeacherDetails } from '../../network/Endpoint';
import { screen } from '@testing-library/react';
import ClassChangeModal from './ClassChangeModal';
import './LessonPage.css';
import { ReactComponent as Logo } from '../../resources/images/arkmath_logo.svg';

const LessonPage = () => {
  const [searchParams] = useSearchParams();
  const [teacher, setTeacher] = useState({});
  const [school, setSchool] = useState({});
  const [clazz, setClazz] = useState({});
  const [students, setStudents] = useState([]);
  const [stage, setStage] = useState({});
  const [showClassChangeModel, setShowClassChangeModel] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
  });
  const [studentLessonStatus, setStudentLessonStatus] = useState({});

  const encodedImTeacherLoginIdParam = searchParams.get('mId') || '';
  const stageCodeParam = searchParams.get('stageCode') || '';

  const fetchTeacherDetails = async () => {
    try {
      const body = {
        encodedImTeacherLoginId: encodedImTeacherLoginIdParam,
        stageCode: stageCodeParam,
      };
      const res = await getTeacherDetails(body);
      const statusCode = res.data.status.code;
      if (statusCode === 'Success') {
        setTeacher(res.data.data.teacher);
        setSchool(res.data.data.school);
        setClazz(res.data.data.clazz);
        setStudents(res.data.data.students);
        setStage(res.data.data.stage);
        const body = {
          classId: res.data.data.clazz.classId,
          stageId: res.data.data.stage.stageId,
        };
        fetchClassLessonStage(body);
      } else {
        alert(`[${statusCode}] ERROR: 오류가 발생했어요. 아크수학으로 문의해 주세요.`);
      }
    } catch (error) {
      alert('오류가 발생했어요. 아크수학으로 문의해 주세요.');
    }
  };

  const fetchStudentsByClass = async (body) => {
    try {
      const res = await getStudentsByClass(body);
      const statusCode = res.data.status.code;
      if (statusCode === 'Success') {
        setStudents(res.data.data.students);
      } else {
        alert(`[${statusCode}] ERROR: 오류가 발생했어요. 아크수학으로 문의해 주세요.`);
      }
    } catch (error) {
      alert('오류가 발생했어요. 아크수학으로 문의해 주세요.');
    }
  };

  const fetchClassLessonStage = async (body) => {
    try {
      const res = await getClassLessonStage(body);
      const statusCode = res.data.status.code;
      if (statusCode === 'Success') {
        setStudentLessonStatus(res.data.data);
      } else {
        alert(`[${statusCode}] ERROR: 오류가 발생했어요. 아크수학으로 문의해 주세요.`);
      }
    } catch (error) {
      alert('오류가 발생했어요. 아크수학으로 문의해 주세요.');
    }
  };

  const openQrCodePage = () => {
    window.open(
      // process.env.REACT_APP_WEB_URL + `/lesson/qrcode?stageId=${stage.stageId}&stageName=${stage.name}&classId=${clazz.classId}`, // QR 코드 페이지 URL
      `https://1promath-dev.shop/onepromath_webgl/arkmath/teacher.html?stageId=${stage.stageId}&stageName=${stage.name}&classId=${clazz.classId}`, // QR 코드 페이지 URL
      'qrcode_window',
      `width=${screen.width},height=${screen.height},top=0,left=0`
    );
  };

  useEffect(() => {
    if (showClassChangeModel) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [showClassChangeModel]);

  const openClassChangeModel = () => {
    setShowClassChangeModel(true);
  };

  const closeClassChangeModel = () => {
    setShowClassChangeModel(false);
  };

  const submitStudentCreation = async (e) => {
    e.preventDefault();
    if (formData.name === '') {
      alert('학생 이름을 입력해 주세요.');
      return;
    }
    if (window.confirm('생성하시겠습니까?')) {
      try {
        const body = {
          name: formData.name,
          imClassId: clazz.imClassId,
          imTeacherLoginId: teacher.imTeacherLoginId,
        };
        const res = await createStudent(body);
        const statusCode = res.data.status.code;
        if (statusCode === 'Success') {
          let student = res.data.data.student;
          setStudents((prevStudents) => [...prevStudents, student]);
          setFormData({
            ...formData,
            name: '',
          });
          alert('생성 완료!');
        } else {
          alert(`[${statusCode}] ERROR: 오류가 발생했어요. 아크수학으로 문의해 주세요.`);
        }
      } catch (error) {
        alert('오류가 발생했어요. 아크수학으로 문의해 주세요.');
      }
    }
  };

  const handleFormData = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    fetchTeacherDetails();
    setFormData({
      ...formData,
      name: '',
    });
  }, []);

  return (
    <div>
      <ClassChangeModal showClassChangeModal={showClassChangeModel}
                        closeClassChangeModel={closeClassChangeModel}
                        imTeacherLoginId={teacher.imTeacherLoginId}
                        setClazz={setClazz}
                        fetchStudentsByClass={fetchStudentsByClass}/>
      <div>
        <div className={'h-[160px] flex justify-center items-center'}>
          <Logo/>
        </div>
        <div className={'flex justify-center'}>
          <div className={'rounded-[20px] border-[0.5px] border-[#dadada] shadow-[0_2px_7px_0_rgba(0,0,0,0.10)] bg-white py-[30px] px-[50px] flex justify-between items-center w-[66%]'}>
            <div>
              <div className={'text-[26px]'}>
                {teacher.name}
              </div>
              <div className={'text-[22px]'}>
                {school.name} / {clazz && clazz.name}
              </div>
            </div>
            <div>
              <button type={'button'} className={'flex justify-center items-center bg-[#557cfc] text-[#ffffff] w-[150px] h-[44px] rounded-full text-[22px]'} onClick={openClassChangeModel}>학급 변경
              </button>
            </div>
          </div>
        </div>
        <div className={'flex justify-center mt-[30px]'}>
          <div className={'rounded-[20px] border-[0.5px] border-[#dadada] shadow-[0_2px_7px_0_rgba(0,0,0,0.10)] bg-white w-[66%]'}>
            <div>
              <div className={'py-[20px] px-[30px] text-[22px]'}>
                {stage.name}
              </div>
              <div className={'border-[0.5px]'}></div>
            </div>
            <div className={'flex justify-between items-center py-[20px] px-[30px]'}>
              <div className={'flex'}>
                <div>
                  {stage.thumbnailImage ? (
                    <img src={`https://sla207651-sla207651.ktcdn.co.kr/stage_thumbnails/${stage.thumbnailImage}.png`} className={'rounded-[20px] max-w-[300px]'} alt={stage.thumbnailImage}/>
                  ) : null}
                </div>
                <div className={'w-[200px] flex justify-center items-center'}>
                  <div>
                    <div className={'flex justify-center items-center'}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="51" height="51" viewBox="0 0 51 51" fill="none">
                        <g clip-path="url(#clip0_938_10534)">
                          <path
                            d="M6.51294 25.6609C6.51294 28.1411 7.00146 30.5971 7.95062 32.8886C8.89978 35.1801 10.291 37.2621 12.0448 39.016C13.7986 40.7698 15.8807 42.161 18.1722 43.1101C20.4636 44.0593 22.9196 44.5478 25.3999 44.5478C27.8802 44.5478 30.3362 44.0593 32.6276 43.1101C34.9191 42.161 37.0012 40.7698 38.755 39.016C40.5088 37.2621 41.9 35.1801 42.8492 32.8886C43.7983 30.5971 44.2869 28.1411 44.2869 25.6609C44.2869 23.1806 43.7983 20.7246 42.8492 18.4331C41.9 16.1417 40.5088 14.0596 38.755 12.3058C37.0012 10.552 34.9191 9.16075 32.6276 8.21159C30.3362 7.26244 27.8802 6.77391 25.3999 6.77391C22.9196 6.77391 20.4636 7.26244 18.1722 8.21159C15.8807 9.16075 13.7986 10.552 12.0448 12.3058C10.291 14.0596 8.89978 16.1417 7.95062 18.4331C7.00146 20.7246 6.51294 23.1806 6.51294 25.6609Z"
                            stroke="#2CB000" stroke-width="4.1971" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M19.1042 25.6609L23.3013 29.858L31.6956 21.4638" stroke="#2CB000" stroke-width="4.1971" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                          <clipPath id="clip0_938_10534">
                            <rect width="50.3652" height="50.3652" fill="white" transform="translate(0.217285 0.478256)"/>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div className={'flex justify-center items-center text-[22px] mt-[10px]'}>
                      학습완료
                    </div>
                    <div className={'flex justify-center items-center text-[24px] mt-[10px] text-[#557CFC]'}>
                      {studentLessonStatus.completedCount}명
                    </div>
                  </div>
                </div>
                <div className={'border'}></div>
                <div className={'w-[200px] flex justify-center items-center'}>
                  <div>
                    <div className={'flex justify-center items-center'}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="51" height="51" viewBox="0 0 51 51" fill="none">
                        <g clip-path="url(#clip0_938_10539)">
                          <path d="M17.9808 8.22179C15.6894 9.1703 13.6071 10.5608 11.853 12.314" stroke="#D3D3D3" stroke-width="4.1971" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M7.76099 18.442C6.80942 20.73 6.3174 23.183 6.31299 25.661" stroke="#D3D3D3" stroke-width="4.1971" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M7.76074 32.8798C8.70926 35.1712 10.0998 37.2534 11.8529 39.0075" stroke="#D3D3D3" stroke-width="4.1971" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M17.981 43.0999C20.269 44.0515 22.7219 44.5435 25.2 44.5479" stroke="#D3D3D3" stroke-width="4.1971" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M32.4189 43.0995C34.7104 42.151 36.7926 40.7605 38.5467 39.0074" stroke="#D3D3D3" stroke-width="4.1971" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M42.6389 32.8799C43.5905 30.5918 44.0825 28.1389 44.0869 25.6609" stroke="#D3D3D3" stroke-width="4.1971" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M42.639 18.4421C41.6905 16.1507 40.3 14.0685 38.5469 12.3144" stroke="#D3D3D3" stroke-width="4.1971" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M32.419 8.22191C30.1309 7.27034 27.678 6.77832 25.2 6.77391" stroke="#D3D3D3" stroke-width="4.1971" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M18.9043 25.6609L23.1014 29.858L31.4956 21.4638" stroke="#D3D3D3" stroke-width="4.1971" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                          <clipPath id="clip0_938_10539">
                            <rect width="50.3652" height="50.3652" fill="white" transform="translate(0.017334 0.478256)"/>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div className={'flex justify-center items-center text-[22px] mt-[10px]'}>
                      미진행
                    </div>
                    <div className={'flex justify-center items-center text-[24px] mt-[10px] text-[#557CFC]'}>
                      {studentLessonStatus.inCompletedCount}명
                    </div>
                  </div>
                </div>
              </div>
              <button type={'button'} className={'flex justify-center items-center bg-[#557cfc] text-[#ffffff] w-[150px] h-[150px] rounded-full text-[22px]'} onClick={openQrCodePage}>
                <div>
                  <div>
                    학습 시작
                  </div>
                  <div className={'text-[#FFFB9F]'}>(QR생성)</div>
                  <div className={'flex justify-center items-center'}>
                    <svg width="26" height="29" viewBox="0 0 26 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M24.0161 12.4886C25.5645 13.3826 25.5645 15.6175 24.0161 16.5114L3.98387 28.0771C2.43548 28.971 0.499999 27.8536 0.499999 26.0656L0.5 2.93437C0.5 1.14644 2.43548 0.0289939 3.98387 0.922956L24.0161 12.4886Z"
                        fill="white"/>
                    </svg>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div className={'flex justify-center mt-[30px]'}>
          <div className={'rounded-[20px] border-[0.5px] border-[#dadada] shadow-[0_2px_7px_0_rgba(0,0,0,0.10)] bg-white py-[30px] px-[50px] w-[66%]'}>
            <div className={'flex justify-center'}>
              <form onSubmit={(e) => submitStudentCreation(e)} className={'w-[30%] flex justify-center border border-[#d9d9d9] rounded-[10px]'}>
                <input type={'text'} name={'name'} value={formData.name || ''} onChange={handleFormData} placeholder={'추가할 학생의 이름을 적어 주세요.'} className={'w-full rounded-l-[10px] px-[4px]'}/>
                <button type={'submit'} className={'w-[100px] rounded-r-[10px] h-[44px] bg-[#D9D9D9]'}>학생 추가</button>
              </form>
            </div>
            <div className={'mt-[30px]'}>
              <table className={'w-full border-separate border-spacing-0'}>
                <thead>
                <tr className={'bg-[#FBFBFB] h-[50px]'}>
                  <th className={'w-[33.3%] border-t-[2px] border-t-[#292929] border-b text-left pl-[20px]'}>번호</th>
                  <th className={'w-[33.3%] border-t-[2px] border-t-[#292929] border-b text-left'}>이름</th>
                  <th className={'w-[33.3%] border-t-[2px] border-t-[#292929] border-b text-left'}>접속번호</th>
                </tr>
                </thead>
                <tbody>
                {students.map((value, index) => (
                  <tr key={index} className={'h-[50px]'}>
                    <td className={`${index === students.length - 1 ? 'border-b-[2px] border-b-[#292929]' : 'border-b '} pl-[20px]`}>{value.studentId}</td>
                    <td className={`${index === students.length - 1 ? 'border-b-[2px] border-b-[#292929]' : 'border-b'}`}>{value.name}</td>
                    <td className={`${index === students.length - 1 ? 'border-b-[2px] border-b-[#292929]' : 'border-b'}`}></td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LessonPage;
