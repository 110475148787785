import './App.css';
import { Route, Routes } from 'react-router-dom';
import LessonPage from './features/lesson/LessonPage';
import QrCodePage from './features/lesson/QrCodePage';

function App () {
  return (
    <div className="App">
      <Routes>
        <Route path="/lesson" element={<LessonPage/>}></Route>
        <Route path="/lesson/qrcode" element={<QrCodePage/>}></Route>
        {/* 상단에 위치하는 라우트들의 규칙을 모두 확인하고, 일치하는 라우트가 없다면 마지막 라우트가 화면에 나타나게 됨. */}
        <Route path="*" element={<LessonPage/>}></Route>
      </Routes>
    </div>
  );
}

export default App;
