import { req } from './Network';

// teacher
export const getTeacherDetails = (body) => {
  return req.get(`/v1/teachers/${body.encodedImTeacherLoginId}/stages/${body.stageCode}/details`);
};

// class
export const createClass = (body) => {
  return req.post(`/v1/classes`, body);
};

export const getClassesByTeacher = (body) => {
  return req.get(`/v1/classes/teachers/${body.imTeacherLoginId}`);
};

// student
export const createStudent = (body) => {
  return req.post(`/v1/students`, body);
};

export const getStudentsByClass = (body) => {
  return req.get(`/v1/students/classes/${body.imClassId}/teachers/${body.imTeacherLoginId}`);
};

// lesson
export const getClassLessonStage = (body) => {
  return req.get(`/v1/lesson/classes/${body.classId}/stages/${body.stageId}`)
}